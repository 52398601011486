<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model layout="inline">
        <a-form-model-item>
          <a-input v-model="name" placeholder="姓名" style="width: 120px" />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="dept" placeholder="部门" style="width: 120px" />
        </a-form-model-item>
      </a-form-model>
      <Padding />

      <a-table bordered :dataSource="filteredList" :loading="loading" rowKey="userId">
        <a-table-column title="部门" data-index="dept"></a-table-column>
        <a-table-column title="姓名" align="center" data-index="name" />
        <a-table-column title="手机号" data-index="mobile" align="center"></a-table-column>

        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="
                  setFace(text)
                  ">录入</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal title="人脸录入" :visible="visible" :footer="null" @cancel="cancel" :forceRender="true">
      <canvas id="canvas" style="margin: 0 auto; display: block" width="160px" height="160px"></canvas>
      <div style="text-align: center;margin-top: 16px">请连接公司 WIFI，扫描二维码，录入人脸。一人一码，请勿分享</div>
    </a-modal>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList } from "@/api/employee";

export default {
  mixins: [watermark],

  data() {
    return {
      name: "",
      dept: "",
      loading: false,

      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      text: {},
      visible: false,
      url: "http://10.23.67.170:8989/"
    };
  },

  computed: {
    filteredList() {
      let list = this.list;
      if (this.name) {
        list = list.filter(
          item => item.name && item.name.indexOf(this.name) > -1
        );
      }
      if (this.dept) {
        list = list.filter(
          item => item.dept && item.dept.indexOf(this.dept) > -1
        );
      }
      return list;
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        console.log(
          `${this.url}?id=${this.text.userId}&name=${this.text.name}&mobile=${this.mobile}`
        );

        new window.AwesomeQR.AwesomeQR({
          text: `${this.url}?id=${this.text.userId}&name=${this.text.name}&mobile=${this.text.mobile}`,
          size: 160,
          margin: 0
        })
          .draw()
          .then(dataURL => {
            const image = new Image();
            image.onload = function() {
              context.drawImage(image, 0, 0);
            };
            image.src = dataURL;
          });
      }
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList()
        .then(res => {
          this.loading = false;
          if (res && Array.isArray(res)) {
            this.list = Object.freeze(
              res.map(item => {
                return {
                  userId: item.userId,
                  name: item.name,
                  dept: item.deptUniqueName,
                  mobile: item.mobile
                };
              })
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setFace(text) {
      this.text = text;
      this.visible = true;
    },
    cancel() {
      this.text = {};
      this.visible = false;
    }
  }
};
</script>